@tailwind base /* Preflight will be injected here */
@tailwind components
@tailwind utilities

@import '~@fortawesome/fontawesome-pro/css/all.min.css'

h1, h2, h3, h4, h5, h6
    color: --var(--80)

h1
    font-size: 2.5rem
    font-weight: 500
h2
    font-size: 2.25rem
    font-weight: 500
h3
    font-size: 1.875rem
    font-weight: 500
h4
    font-size: 1.5rem
    font-weight: 500
h5
    font-size: 1.25rem
h6
    font-size: 1.125rem

hr
    border-top: 1px solid theme('colors.gray.300')
    margin: 1rem -0.5rem

.link
    @apply text-blue-600 underline hover:text-blue-800

#nprogress .bar
    background: theme('colors.orange.600') !important
    height: 4px !important

.text-success
    color: theme('colors.green.600')
.text-info
    color: theme('colors.blue.600')
.text-danger
    color: theme('colors.red.600')
.text-warning
    color: theme('colors.yellow.500')

.list-inside
    margin-left: 0.75rem

.form-group
    @apply mb-3

.form-label
    @apply block tracking-wide font-bold mb-1

.form-input
    @apply appearance-none block w-full bg-white border border-gray-300 text-gray-700 rounded py-3 px-4 mb-1 leading-tight
    &:focus
        @apply outline-none bg-white border-gray-500

.form-select
    @apply block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 mb-3 rounded leading-tight
    &:focus
        @apply outline-none bg-white border-gray-500
    &:disabled
        @apply border-gray-200
        color: theme('colors.gray.300')

.form-select-sm
    @apply block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-1 px-2 pr-4 mb-1 rounded leading-tight

    &:focus
        @apply outline-none bg-white border-gray-500
    &:disabled
        @apply border-gray-200
        color: theme('colors.gray.300')

.btn
    transition: 150ms ease-in-out

.btn-white
    @apply inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white

    &:hover
        @apply bg-gray-100

    &:focus
        @apply outline-none

.btn-primary
    @apply bg-blue-400 text-white font-bold py-2 px-4 rounded
    &:hover
        @apply bg-blue-600 cursor-pointer

.btn-success
    @apply bg-green-500 text-white font-bold py-2 px-4 rounded
    &:hover
        @apply bg-green-700 cursor-pointer

.btn-warning
    @apply bg-yellow-500 text-white font-bold py-2 px-4 rounded
    &:hover
        @apply bg-yellow-600 cursor-pointer

.btn-danger
    @apply bg-red-500 text-white font-bold py-2 px-4 rounded
    &:hover
        @apply bg-red-700 cursor-pointer

.btn-outline-primary
    @apply bg-transparent text-blue-700 font-semibold py-2 px-4 border border-primary rounded

    &:hover
        @apply bg-blue-500 text-white border-transparent

.text-icon-gray
    @apply text-gray-600

    &:hover
        @apply text-blue-600

.table
    background: theme('colors.white')
    @apply w-full
    overflow-x: auto

    thead
        text-align: left
        background: theme('colors.gray.100')

        th
            font-weight: normal
            text-transform: uppercase
            color: theme('colors.gray.600')
            border-top: 1px solid theme('colors.gray.300')
            border-bottom: 1px solid theme('colors.gray.300')
            @apply px-2 py-4 text-sm

    .vuetable-checkboxes, .vuetable-th-checkbox-id
        @apply pl-4

    tr
        border-bottom: 1px solid theme('colors.gray.300')
        &:last-child
            border-bottom: none
        td
            @apply px-2 py-2

            &.vuetable-empty-result
                @apply py-5

// Responsive table
.table-scrollable
    width: 100%
    overflow-y: auto

.table-scrollable::-webkit-scrollbar
    -webkit-appearance: none
    width: 14px
    height: 14px

.table-scrollable::-webkit-scrollbar-thumb
    border-radius: 8px
    border: 3px solid #fff
    background-color: rgba(0, 0, 0, .3)

// Container
main.container
    padding-left: 10px
    padding-right: 10px
    margin: 0 auto

// Navigation
.main-navigation, .sub-navigation
    .navigation-items a
        transition: 150ms ease-in-out
        @apply px-3 py-2 rounded-md text-sm font-medium text-teal-50

        &.active
            @apply text-white bg-teal-800

        &:hover
            @apply text-white bg-teal-700
        &:focus
            @apply outline-none text-white bg-teal-700

        &.active
            border-color: white
    .mobile-navigation-items a
        @apply block text-base

.second-level-navigation
    @apply mr-5
    a
        @apply flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md transition ease-in-out duration-150

        &.active
            @apply text-gray-900 bg-gray-200

        &:not(:first-child)
             @apply mt-1

        &:hover
            @apply text-gray-900 bg-gray-100

        &:focus
            @apply outline-none text-gray-900 bg-gray-100


// Pagination
.page-item
    display: inline-block
    padding: .25rem .5rem
    color: theme('colors.blue.500')

    &.disabled
        color: theme('colors.gray.500')
        a
            cursor: default
    &.active
        color: theme('colors.blue.600')
        font-weight: bold

// Tooltip
.tooltip
    display: block !important
    z-index: 10000

    .tooltip-inner
        background: theme('colors.gray.700')
        color: white
        border-radius: 16px
        padding: 5px 15px 4px

    .tooltip-arrow
        width: 0
        height: 0
        border-style: solid
        position: absolute
        margin: 0 5px
        border-color: theme('colors.gray.700')
        z-index: 1
        border-width: 5px 5px 0 5px
        border-left-color: transparent !important
        border-right-color: transparent !important
        border-bottom-color: transparent !important
        bottom: -5px
        left: calc(50% - 5px)

    &.popover
        $color: #f9f9f9

        .popover-inner
            background: $color
            color: black
            padding: 24px
            border-radius: 5px
            box-shadow: 0 5px 30px rgba(black, .1)

        .popover-arrow
            border-color: $color

    &[aria-hidden='true']
        visibility: hidden
        opacity: 0
        transition: opacity .15s, visibility .15s

    &[aria-hidden='false']
        visibility: visible
        opacity: 1
        transition: opacity .15s

// Cookie consent
.js-cookie-consent
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    background: theme('colors.teal.500')
    color: #fff
    padding: 1rem 1rem


// Animation
.card-offset-animation
    display: block
    top: 0px
    border-radius: 4px
    padding: 32px 24px
    margin: 12px
    overflow: hidden
    position: relative

    &:hover
        transition: all 0.2s ease-out
        box-shadow: 0 4px 8px rgba(38, 38, 38, 0.2)
        top: -4px
        background-color: white

    &:before
        content: ""
        position: absolute
        z-index: -1
        top: -16px
        right: -16px
        height: 32px
        width: 32px
        border-radius: 32px
        transform: scale(2)
        transform-origin: 50% 50%
        transition: transform 0.15s ease-out

    &:hover:before
        transform: scale(2.15)
